// VUE-ROUTER 4 需要使用以下方式引入router
import { createRouter, createWebHistory } from 'vue-router';
import SiteLayout from '@/components/layout/SiteLayout.vue';
import AdminLayout from '@/components/layout/DashboardLayout.vue';
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/login/LoginPage.vue';
import AdminDashboard from '@/views/adminDashboard/AdminDashboard.vue';
import AdminLogin from '@/views/adminDashboard/AdminLogin.vue';
import DashboardHeader from '@/components/dashboard/DashboardHeader.vue';
import DashboardSidebar from '@/components/dashboard/DashboardSidebar.vue';
import MemberManager from '@/components/dashboard/MemberManager.vue';
import ProductManager from '@/components/dashboard/ProductManager.vue';
import SiteManagerMenu from '@/components/dashboard/sitemanager/SiteManagerMenu.vue';


const routes = [
  {
    path: '/',
    name: 'SiteLayout',
    component: SiteLayout,
    children: [
      {
        path: '/',
        name: 'HomePage',
        components: {
          content: HomePage
        },
        meta: { title: '首页' }
      },
      {
        path: '/login',
        name: 'LoginPage',
        components: {
          content: LoginPage
        }
      }
    ]
  },
  {
    path: '/manager',
    name: 'AdminLayout',
    component: AdminLayout,
    children: [
      {
        path: 'home',
        name: 'AdminDashboard',
        components: {
          header: DashboardHeader,
          content: AdminDashboard,
          sidebar: DashboardSidebar
        },
        meta: { title: '管理后台' }
      },
      {
        path: 'login',
        name: 'AdminLogin',
        components: {
          content: AdminLogin
        },
        meta: { title: '管理后台登录' }
      },
      {
        path: 'user',
        name: 'MemberManager',
        components: {
          header: DashboardHeader,
          content: MemberManager,
          sidebar: DashboardSidebar
        },
        meta: { title: '用户管理' }
      },
      {
        path: 'product',
        name: 'ProductManager',
        components: {
          header: DashboardHeader,
          content: ProductManager,
          sidebar: DashboardSidebar
        },
        meta: { title: '商品管理' }
      },
      {
        path: 'site-manager-menu',
        name: 'SiteManagerMenu',
        components: {
          header: DashboardHeader,
          content: SiteManagerMenu,
          sidebar: DashboardSidebar
        },
        meta: { title: '官网管理' }
      }    
    ]
  }
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '纵横派';
  next();
});

export default router;
