<template>
  <div class="common-layout">
    <el-container>
      <!-- 主体 -->
      <el-main :class="{ 'with-fixed-header': $route.name === 'HomePage' }">
        <div class="banner">
          <img src="@/assets/images/banner/banner01.jpg" alt="banner">
        </div>
        <div class="introduction">
          <div class="title">
            <h1>我们能做什么？</h1>
          </div>
          <div class="card-container">
            <el-card>
              <template #header>
                <div class="card-header">
                  <span>宝藏工具箱</span>
                </div>
              </template>
              <p class="text item">这将会是你的一个宝藏工具箱，汇集了各类高效工具，致力于极大提升你的工作效率。无论你是开发人员、设计师、项目经理，还是普通用户，这个工具箱都能满足你的需求</p>
            </el-card>
            <el-card>
              <template #header>
                <div class="card-header">
                  <span>优质代码库</span>
                </div>
              </template>
              <p class="text item">优质代码库是一个汇集各类场景下代码实现的宝藏资源，真正实现了复制粘贴即可完成工作的便捷体验。无论你面临什么样的编程任务，都能在这里找到高质量的解决方案，助你快速、高效地完成项目。</p>
            </el-card>
            <el-card>
              <template #header>
                <div class="card-header">
                  <span>定制化选项</span>
                </div>
              </template>
              <p class="text item">定制化选项，始终关注你的每一个难题。无论遇到什么困难，都可以尽情诉说，我们会集思广益，汇聚大家的智慧，共同找到最佳解决方案。你的问题，就是我们努力的方向。</p>
            </el-card>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>


<script>
export default {
  name: 'HomePage',
  data() {
    return {
      activeIndex: '1'
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>

<style scoped>
.common-layout {
  text-align: center;
}

.banner {
  max-height: calc(100vh); 
  overflow: hidden;
  /* 抵消 header 的高度 */
  margin-top: -60px; 
}

.banner img {
  width: 100%;
  height: calc(100vh); 
  object-fit: cover;
  object-position: center;
}

.content {
  margin-top: 50px;
}

.content h1 {
  font-size: 32px;
  color: #5b3cc4;
}

.content p {
  font-size: 18px;
  margin: 20px 0;
}

.el-button {
  background-color: #f56c6c;
  border-color: #f56c6c;
}

::v-deep .el-main {
  padding: 0;
}

/* 添加此样式来处理header在首页时覆盖内容 */
.with-fixed-header {
  margin-top: 0px; /* 根据实际的header高度调整 */
}

/* 卡片容器样式 */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 水平居中 */
  max-width: 100%;
  margin: 0 auto;
}

.el-container .el-main .introduction h1{
  margin-bottom: 0px;
}

.card-container .el-card {
  border-radius: 10%;
  flex: 0 1 23%;
  margin: 10px;
  background: linear-gradient(to bottom, #8DAFFC, #C9A2FA);
}

::v-deep .el-card__header {
  border-radius: 50%;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .common-layout {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
  }

  .banner {
    display: flex;
    flex-direction: column;
    max-height: none;
    overflow: visible;
  }

  .banner img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .with-fixed-header {
    margin-top: 0; /* 移动端不需要额外的margin */
  }

  .card-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .card-container .el-card {
    flex: none;
    width: 90%;
    margin: 10px 0;
  }
}
</style>
