<template>
      <!-- 侧边栏 -->
      <el-aside class="dashboard-el-aside" width="190px" style="background-color: #2E3B4E; color: #fff;">
        <!-- HEADER Logo 区域 -->
        <div class="header-logo">
            <img src="@/assets/logo.png" alt="Logo" class="logo-img" />
        </div>
        <el-menu default-active="1" class="el-menu-vertical" background-color="#2E3B4E" text-color="#fff" active-text-color="#409EFF">
          <el-menu-item index="1"  @click="$router.push('/manager/home')">
            <i class="iconfont icon-kaifapingtai"></i>
            <span>网站概况</span>
          </el-menu-item>
          <el-menu-item index="2" @click="$router.push('/manager/site-manager-menu')">
            <i class="iconfont icon-guanwangguanli"></i>
            <span>官网管理</span>
          </el-menu-item>
          <el-menu-item index="3" @click="$router.push('/manager/user')">
            <i class="iconfont icon-gongyingshang"></i>
            <span>会员信息</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="iconfont icon-caigouguanli"></i>
            <span>订单管理</span>
          </el-menu-item>
          <el-menu-item index="5" @click="$router.push('/manager/product')">
            <i class="iconfont icon-cangchuguanli"></i>
            <span>商品管理</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="iconfont icon-baobiaoguanli"></i>
            <span>图片管理</span>
          </el-menu-item>
          <el-menu-item index="7">
            <i class="iconfont icon-shenpiguanli"></i>
            <span>发布管理</span>
          </el-menu-item>
          <el-menu-item index="8">
            <i class="iconfont icon-shezhi"></i>
            <span>系统配置</span>
          </el-menu-item>
          <el-menu-item index="9">
            <i class="iconfont icon-kucunyujing"></i>
            <span>系统预警</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
  </template>
  
  <script>
  export default {
    data() {
      return {
          // 其他数据
      };
    }
  };
  </script>
  
  <style lang="scss">
  .dashboard-el-aside {
    .header-logo {
        display: flex;
        align-items: center;
        height: 60px; /* 保持与 header 一致的高度 */
        justify-content: space-around;
    }
    
    .logo-img {
        height: 100%; /* 图片高度填充整个 sidebar-logo 区域 */
    }
    .el-menu-item {
        margin-bottom: 10px;
        font-size: 15px;
    }
    .el-menu-vertical {
        margin-top: 20px;
        border-right: none;
        background-color: #2E3B4E;
    }
    .iconfont{
        margin-right: 10px;  
        font-size: xx-large;
    }
    .el-header {
        background-color: #f5f5f5;
        padding: 20px;
    }
    .el-main {
        padding: 20px;
    }
  }
  </style>
  