<template>
  <!-- 主体内容 -->
      <el-main class="dashboard-content">
        <!-- TAB 区域 -->
        <div class="table-container">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-card shadow="hover">
                <div>总访问量</div>
                <div class="num">36</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>今日访问量</div>
                <div class="num">4</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>会员人数</div>
                <div class="num">117</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>今日新增会员</div>
                <div class="num">295</div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        
      </el-main>
</template>

<script>
export default {
  name: 'AdminDashboard',
  data() {
    return {
      
    };
  }
};
</script>

<style scoped>
.dashboard-content{
  background: whitesmoke;
}
.table-container {
  margin-bottom: 20px;
}

.num {
  font-size: 24px;
  color: #409EFF;
  font-weight: bold;
  text-align: center;
}

#dashboard .el-main {
  padding: 20px;
}
</style>
