<template>
    <el-menu :mode="mode" class="el-menu-demo" :default-active="defaultActive" @select="$emit('select', ...arguments)">
      <el-menu-item index="1">首页</el-menu-item>
      <el-menu-item index="2">工具</el-menu-item>
      <el-menu-item index="3">资源</el-menu-item>
      <el-menu-item index="4">文章</el-menu-item>
    </el-menu>
  </template>
  
  <script>
  export default {
    name: 'MenuList',
    props: {
      mode: {
        type: String,
        required: true
      },
      defaultActive: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .el-menu-demo {
    width: 100%;
  }
  
  .el-menu-item {
    font-size: 20px;
    color: aliceblue;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .el-menu-demo {
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #333;
    }
    .el-menu-item {
        font-size: 20px;
        color: rgb(9, 10, 10);
        font-weight: 500;
    }
  }
  </style>
  