<template>
  <el-header class="main-navbar" :class="{ 'home-page': isHomePage, 'scrolled': isScrolled }">
    <div class="navbar-content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="纵横派">
      </div>
      <template v-if="!isMobile || menuVisible">
        <MenuList :mode="isMobile ? 'vertical' : 'horizontal'" :default-active="activeIndex" @select="handleSelect" />
      </template>
      <el-button class="menu-toggle" @click="toggleMenu" v-if="isMobile">
        <i class="iconfont icon-daohangcaozuo"></i>
      </el-button>
    </div>
    <transition name="slide-left">
      <div class="mobile-menu" v-show="menuVisible" @click="toggleMenu">
        <div class="menu-content" @click.stop>
          <MenuList mode="vertical" :default-active="activeIndex" @select="handleSelect" />
        </div>
      </div>
    </transition>
  </el-header>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  name: 'MainNavbar',
  components: {
    MenuList
  },
  data() {
    return {
      activeIndex: '1',
      isHomePage: false,
      isMobile: false,
      menuVisible: false,
      isScrolled: false
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    checkIfHomePage() {
      this.isHomePage = this.$route.name === 'HomePage';
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.menuVisible = false;
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    }
  },
  mounted() {
    this.checkIfHomePage();
    this.handleResize();
    window.addEventListener('scroll', this.handleScroll);
    this.$router.afterEach(this.checkIfHomePage);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
.main-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s;
  background: transparent;
}

.el-header .navbar-content .logo {
  width: 10%;
  padding: 0px 5% 0px 10%;
}

.el-header .navbar-content .logo img {
  height: 55px;
}

.home-page {
  background: linear-gradient(to bottom, rgb(235 242 250 / 84%), rgb(170 181 198 / 91%));;
  box-shadow: none;
}

.home-page.scrolled {
  background: linear-gradient(to bottom, rgb(235 242 250), rgb(170 181 198));
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.navbar-content {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.logo {
  font-size: 24px;
  color: #f5f4f7;
  padding: 10px 0;
}

::v-deep .el-menu {
  background-color: transparent;
}

.el-menu-item {
  font-size: 20px;
  color: aliceblue;
  font-weight: 500;
}

.el-menu-demo {
  flex-grow: 1;
  border-bottom: none;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar-content {
    width: 100%;
    padding: 0 10px;
  }

  .logo {
    padding: 0;
    width: auto;
  }

  .iconfont {
    font-size: 30px;
    color: white;
  }

  .navbar-content .el-button {
    height: 39px;
  }

  .menu-toggle {
    display: block;
    background: transparent;
    font-size: 24px;
    padding: 4px;
  }

  .el-header .navbar-content .logo {
    padding: 0;
  }
  
  .mobile-menu {
    position: fixed;
    margin-top: 60px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }

  .menu-content {
    width: 60%;
    height: 100%;
    background-color: white;
    position: relative;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.3s;
  }

  .slide-left-enter, .slide-left-leave-to {
    transform: translateX(-100%);
  }
}
</style>
