<template>
    <!-- 主体内容 -->
      <el-main class="dashboard-site-manager-content">
        <div class="table-container">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-card shadow="hover">
                <div>广告弹窗</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>轮播图</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>导航设置</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>首页宫格区</div>
              </el-card>
              
            </el-col>
          </el-row>
        </div>
      </el-main>
  </template>
  
  <script>
  export default {
    name: 'SiteManagerMenu',
    data() {
      return {
      }
    },
    methods: {
    }
  }
  </script>
  
  <style scoped>  
  
  
  .user-avatar {
    margin-right: 10px;
  }
  
  .user-name {
    font-size: 14px;
    color: #333;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .el-divider {
    height: 25px;
    margin: 0 10px;
  }
  </style>
  