import axios from 'axios';

export const postApi = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            baseURL: 'http://127.0.0.1:18080',
            headers: {
                'Content-Type': 'application/json',
                'changeOrigin': true
            }
        }).then((response) => {
            resolve(response.data)
        }, error => {
            reject(error);
        })
    })
}

const instance = axios.create({
    baseURL: 'http://127.0.0.1:18080',
    timeout: 5000
})

export const getApi = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        instance.get(url, params, {}).then((response) => {
            resolve(response.data)
        }, error => {
            reject(error)
        })
    })
}
