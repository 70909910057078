<template>
    <div class="login-page">
      <div class="login-container">
        <div class="login-box">
          <h2>欢迎登录</h2>
          <h3>纵横派运营管理系统</h3>
          <el-form :model="loginForm" class="login-form">
            <el-form-item>
              <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="loginForm.captcha" placeholder="验证码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
            </el-form-item>
            <el-button type="primary" @click="submitLogin" class="login-button">登 录</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive } from 'vue'
  import { useRouter } from 'vue-router'
  
  export default {
    name: 'AdminLogin',
    setup() {
        // 使用useRouter钩子获取路由实例
        const router = useRouter()
      const loginForm = reactive({
        phone: '',
        password: '',
        captcha: '',
        rememberMe: false,
        loginSuccess: true
      })
  
      const submitLogin = () => {
        console.log('Login form data:', loginForm)
        // 在这里执行登录逻辑，例如发送请求到服务器进行验证
        // 如果验证成功，可以跳转到主页或者其他页面
        // 如果验证失败，可以显示错误消息给用户
        // 例如：
        if (loginForm.loginSuccess) {
            // 使用 router.push 进行路由跳转
            router.push({ name: 'AdminDashboard' }) 
        } else {
          this.$message.error('登录失败，请检查用户名和密码')
        }
        
      }
  
      return {
        loginForm,
        submitLogin
      }
    }
  }
  </script>
  
  <style scoped>
  .login-page {
    height: 100vh;
    background: url('@/assets/images/dashboard/login_bag.jpg') no-repeat center center;
    background-size: cover; /* 确保图片覆盖整个背景区域 */
    display: flex;
    justify-content: center;
    align-items: center;
    }
  
    .login-container {
    width: 800px;
    height: 400px;
    padding: 40px;
    background: url('@/assets/images/dashboard/login_box.png') no-repeat center center;
    background-size: cover; /* 确保图片覆盖整个背景区域 */
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    }

    .login-box {
        width: 340px;
        float: inline-end;
    }
  .login-box h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .login-box h3 {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .login-form {
    width: 100%;
  }
  
  .login-button {
    width: 100%;
    margin-top: 20px;
  }
  </style>
  