import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import Vue3Storage from "vue3-storage"
import 'element-plus/dist/index.css'
import './assets/font/alifont/iconfont.css';
import router from './router'
import store from './store';

createApp(App).use(router).use(store).use(ElementPlus).use(Vue3Storage, { namespace: "app_", storage: 'local' }).mount('#app')
