// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      userLoggedIn: false,
      userAvatar: '',
      userNickname: '',
    };
  },
  mutations: {
    setUserLoggedIn(state, payload) {
      state.userLoggedIn = payload.loggedIn;
      state.userAvatar = payload.avatar;
      state.userNickname = payload.nickname;
    },
    logout(state) {
      state.userLoggedIn = false;
      state.userAvatar = '';
      state.userNickname = '';
    }
  },
  actions: {
    // 定义触发mutations的方法
    increment(context) {
      context.commit('increment');
    }
  },
  getters: {
    // 定义从状态派生的值
    count: state => state.count
  }
});
