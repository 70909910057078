<template>
  <div class="common-layout">
    <header class="layout-header">
      <div class="navbar-container">
        <MainNavbar />
      </div>
    </header>
    <main class="layout-content" :class="{ 'with-fixed-header': $route.name === 'HomePage' }">
      <router-view name="content" />
    </main>
    <el-footer>
      <div class="footer-content">
        <div>
          <span>© {{ currentYear }} www.zonghengpai.com online service. All rights reserved.</span>
        </div>
        <div>
          <span>网站备案号：苏ICP备2024059129号</span>
        </div>
        <div>
          <span>本站为非营利性个人网站，本站所有资源来自于互联网，版权属原著所有，如有需要请购买正版。如有侵权，敬请来信联系我们，我们立刻删除。</span>
        </div>
      </div>
    </el-footer>
  </div>
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue';

export default {
  name: 'SiteLayout',
  components: {
    MainNavbar
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.common-layout {
  text-align: center;
}

/* .with-fixed-header {
  margin-top: 60px;
} */

::v-deep .el-footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
  background: black;
  --el-footer-height: 100%;
}

.footer-content {
  padding: 10px 0;
  font-size: 14px;
  color: #fff;
}

</style>
