<template>
    <div class="login-page">
      <div class="left-panel">
        <!-- 左侧整体图片 -->
        <!-- <img src="./../../../public/images/loginPage/login_background.png" alt="Background" class="background-image" /> -->
      </div>
      <div class="right-panel">
        <!-- 右侧登录框 -->
        <el-card class="login-card">
          <h2>登录</h2>
          <el-form ref="loginForm" :model="loginForm" label-width="0">
            <el-form-item prop="email">
              <el-input v-model="loginForm.email" placeholder="邮箱" clearable prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" placeholder="密码" clearable prefix-icon="el-icon-lock" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <!-- 隐私政策勾选框 -->
              <el-checkbox v-model="loginForm.agree">我同意《用户协议》和《隐私政策》</el-checkbox>
            </el-form-item>
            <el-form-item  class="button-container">
              <div class="button-width">
                <el-button type="primary" @click="login">登录</el-button>
              </div>
            </el-form-item>
            <el-form-item class="forgot-register-row">
              <div>
                <el-link @click="resetPassword">忘记密码？</el-link>
                <span>|</span>
                <el-link @click="goToRegister">立即注册</el-link>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
  </template>

<script>
import { postApi } from '@/utils/request.js';

export default {
  name : 'LoginPage',
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
        agree: false // 隐私政策勾选状态
      }
    };
  },
  methods: {
    login() {
      if (this.loginForm.agree) {
        // 使用postApi函数发送登录请求
        postApi('api/account/login', {
          loginNum: this.loginForm.email,
          passWord: this.loginForm.password
        }).then(response => {
          // 登录成功的处理
          this.$storage.setStorageSync('user', response.data.customer);
          this.$storage.setStorageSync('token', response.data.jwtToken);
          this.loginSuccess(response.data.customer.headImg, response.data.customer.userName);
          this.$router.push({ name: 'HomePage', params: { userLoggedIn: true } });
        }).catch(error => {
          // 登录失败的处理
          console.error('登录失败', error);
        });
      } else {
        // 未勾选隐私政策的处理
        console.error('请同意用户协议和隐私政策');
      }
    },
    resetPassword() {
      // 实现密码重置逻辑
      console.log('密码重置');
    },
    goToRegister() {
      // 跳转到注册页面
      console.log('跳转到注册页面');
    },
    loginSuccess(avatar, nickname) {
      this.$store.commit('setUserLoggedIn', {
        loggedIn: true,
        avatar: avatar,
        nickname: nickname
      });
      // 登录成功后跳转到主页
      this.$router.push({ name: 'HomePage' });
    },
  }
};
</script>
  
  <style scoped>
  .login-page {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  
  .left-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px; /* 添加右边内边距 */
}

.background-image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.right-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px; /* 添加左边内边距 */
}
  
  .login-card {
    width: 350px;
    padding: 20px;
    text-align: center;
  }
  
  .button-container, .button-container /deep/ .el-form-item__content{
    display: flex;
    justify-content: center;
  }

  .button-container .button-width, .button-container .button-width .el-button{
    width: 90%;
  }

  .forgot-register-row, .forgot-register-row /deep/ .el-form-item__content {
    display: flex;
    justify-content: center;
  }
  
  .forgot-register-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .forgot-register-row span {
    margin: 0 10px; /* 调整分隔符间距 */
  }
  
  .register-link {
    margin-top: 20px;
    text-align: center;
  }
  </style>
  