<template>
  <el-container id="dashboard">
    <router-view name="sidebar"></router-view>
    <!-- 主体区域，包括 header 和 content -->
    <el-container class="main-container">
       <!-- Header 固定在顶部 -->
      <router-view name="header"></router-view>
      <!-- Content 区域 -->
      <router-view name="content"></router-view>
    </el-container>
  </el-container>
</template>
  
  <script>
  
  export default {
    name: "AdminLayout",
    components: {
    
    }
  };
  </script>
  
  <style scoped>
  /* 固定 Sidebar 样式，左侧整体高度 */
.dashboard-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh; /* 全屏高度 */
  background-color: #2E3B4E;
  color: #fff;
  overflow: auto; /* 如果侧边栏内容超出，滚动 */
}
 /* 主体容器：包含 Header 和 Content */
.main-container {
  /* margin-left: 220px; 留出 Sidebar 的宽度 */
  height: 100vh; /* 主体高度 */
  display: flex;
  flex-direction: column;
}
/* 固定 Header 样式 */
.dashboard-header {
  height: 60px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin-bottom: 20px;
}
/* Content 区域 */
.dashboard-content {
  padding: 20px;
  overflow: auto; /* 使内容可滚动 */
  flex: 1; /* 让 Content 占据除 Header 之外的剩余空间 */
}
  </style>
  