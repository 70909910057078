<template>
    <el-header class="dashboard-header" height="60px">
      <div class="header-content">
        <div class="user-info">
          <el-avatar size="large" icon="el-icon-user" class="user-avatar" />
          <span class="user-name">管理员</span>
        </div>
        <div class="header-actions">
          <el-button type="text" icon="el-icon-setting">设置</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" icon="el-icon-switch-button">退出</el-button>
        </div>
      </div>
    </el-header>
  </template>
  
  <script>
  export default {
    name: 'DashboardHeader'
  }
  </script>
  
  <style scoped>  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .user-avatar {
    margin-right: 10px;
  }
  
  .user-name {
    font-size: 14px;
    color: #333;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .el-divider {
    height: 25px;
    margin: 0 10px;
  }
  </style>
  