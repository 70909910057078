<template>
    <!-- 主体内容 -->
      <el-main class="dashboard-member-content">
        <div class="table-container">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-card shadow="hover">
                <div>今日已办事件</div>
                <div class="num">36</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>我的统计图表</div>
                <div class="num">4</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>今日采集信息</div>
                <div class="num">117</div>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover">
                <div>今日登记信息</div>
                <div class="num">295</div>
              </el-card>
              
            </el-col>
          </el-row>
        </div>
        <el-table :data="tableData" border style="width: 100%; margin-top: 20px;">
            <el-table-column prop="uid" label="编号" width="180"></el-table-column>
            <!-- 头像列 -->
            <el-table-column label="头像">
            <template #default="scope">
                <el-image
                :src="scope.row.avatar"
                style="width: 50px; height: 50px; border-radius: 50%;"
                fit="cover"
                alt="用户头像"
                />
            </template>
            </el-table-column>
            <el-table-column prop="username" label="昵称"></el-table-column>
            <el-table-column prop="mobile" label="手机号"></el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="label" label="标签"></el-table-column>
            <el-table-column prop="operate" label="操作">
                <template #default="scope">
                    <el-button type="primary" size="mini" @click="handleShow(scope.$index, scope.row)">查看详情</el-button>
                    <!-- 设置 -->
                     <el-button type="warning" size="mini" @click="handleEdit(scope.$index, scope.row)">设置</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="150"
          style="text-align: right; margin-top: 20px;"
        />
      </el-main>
  </template>
  
  <script>
  export default {
    name: 'MemberManager',
    data() {
      return {
        tableData: [
            {
            uid: '1',
            avatar: 'https://img2.baidu.com/it/u=3645615846,4138369375&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400',
            username: '用户1',
            mobile: '1234567890',
            status: '活跃',
            label: 'VIP',
            },
            {
            uid: '2',
            avatar: 'https://iknow-pic.cdn.bcebos.com/d043ad4bd11373f001760302b60f4bfbfaed04e5',
            username: '用户2',
            mobile: '0987654321',
            status: '不活跃',
            label: '普通用户',
            }
        ]
      }
    },
    methods: {
      handleShow(index, row) {
        console.log(index, row);
      },
      handleEdit(index, row) {
        console.log(index, row);
      }
    }
  }
  </script>
  
  <style scoped>  
  
  
  .user-avatar {
    margin-right: 10px;
  }
  
  .user-name {
    font-size: 14px;
    color: #333;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .el-divider {
    height: 25px;
    margin: 0 10px;
  }
  </style>
  