<template>
    <div class="product-content">
        <!-- 商品筛选信息 -->
      <div class="product-info">
      <!-- 顶部表单区域 -->
      <el-form :inline="true" :model="form">
        <el-form-item label="商品名">
            <el-input v-model="form.supplier"></el-input>
        </el-form-item>
        <el-form-item label="商品编码">
            <el-input v-model="form.supplierName"></el-input>
        </el-form-item>
        <el-form-item label="在售状态">
            <el-select v-model="form.deliveryMethod" placeholder="请选择">
            <el-option label="在售" value="onSale"></el-option>
            <el-option label="停售" value="noSale"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="库存状态">
            <el-select v-model="form.stockMethod" placeholder="请选择">
            <el-option label="有货" value="inStock"></el-option>
            <el-option label="缺货" value="noStock"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="商品类型">
            <el-select v-model="form.typeMethod" placeholder="请选择">
            <el-option label="书籍" value="book"></el-option>
            <el-option label="电子数码" value="electronics"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary">确定</el-button>
        </el-form-item>
    </el-form>
      </div>
  
      <!-- 商品表格 -->
      <el-table :data="products" style="width: 100%">
        <el-table-column prop="image" label="商品图" width="100">
          <template #default="scope">
            <el-image :src="scope.row.image" style="width: 50px; height: 50px;"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="spec" label="商品规格"></el-table-column>
        <el-table-column prop="type" label="商品type"></el-table-column>
        <el-table-column prop="quantity" label="商品库存"></el-table-column>
        <el-table-column prop="price" label="商品单价"></el-table-column>
        <el-table-column prop="discount" label="折扣"></el-table-column>
        <el-table-column prop="category" label="所属分类"></el-table-column>
        <el-table-column label="全选">
          <template #default="scope">
            <el-radio v-model="selectedProduct" :label="scope.row.id"></el-radio>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalItems"
        style="text-align: right; margin-top: 20px;"
        @current-change="handlePageChange"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProductManager',
    data() {
      return {
        form: {
          supplier: '',
          supplierName: '',
          supplierAddress: '',
          deliveryMethod: '',
          contact: '',
          logisticsNumber: '',
          logisticsCompany: '',
          deliveryTime: '',
          remark: '',
        },
        products: [
          { id: 1, image: 'https://example.com/image1.jpg', name: '黄瓜', spec: '10公斤/箱', unit: '箱', quantity: 5, price: 40, subtotal: 200, category: '生鲜 > 果蔬' },
          { id: 2, image: 'https://example.com/image2.jpg', name: '鸡胸肉', spec: '10公斤/箱', unit: '箱', quantity: 10, price: 40, subtotal: 400, category: '生鲜 > 果蔬' },
          { id: 3, image: 'https://example.com/image3.jpg', name: '牛后腿肉', spec: '-------', unit: '公斤', quantity: 50, price: 15, subtotal: 750, category: '生鲜 > 果蔬' }
        ],
        totalItems: 65, // 总商品数量
        totalAmount: 1350, // 总金额
        selectedProduct: null
      };
    },
    methods: {
      selectProduct() {
        console.log('选择商品');
      },
      handlePageChange(page) {
        console.log('当前页:', page);
      }
    }
  };
  </script>
  
  <style scoped>
    ::v-deep .el-form-item__content{
        min-width: 160px;
    }
  
  .product-content {
    padding: 0px 50px;
  }
  
  .product-info {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
  }
  </style>
  